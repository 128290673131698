import React, { useState } from "react";

import styles from "./Accordion.module.scss";

const Accordion = ({ data = [] }) => {
	const [activeIndex, setActiveIndex] = useState(null);

	const renderItems = data.map((item, index) => {
		const isActive = activeIndex === index;

		return (
			<div
				// eslint-disable-next-line react/no-array-index-key
				key={`${index} + ${item.title}`}
			>
				<button
					onClick={() => setActiveIndex(index)}
					className="m-b-m"
					type="button"
				>
					<h2>{item.title}</h2>
				</button>
				{isActive && <div>{item.content}</div>}
			</div>
		);
	});

	return <div>{renderItems}</div>;
};

export default Accordion;
