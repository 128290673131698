import React from "react";
import PropTypes from "prop-types";
import Img from "gatsby-image";
import Accordion from "src/components/generic/Accordion/Accordion";

import { FaCheckSquare } from "react-icons/fa";
import styles from "./HowItWorks.module.scss";

const propTypes = {
	title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
	contentSetKey: PropTypes.string
};

const defaultProps = {
	contentSetKey: "default",
	title: "How Piped Out Works"
};

const accordionContent = [
	{
		title: (
			<span>
				<FaCheckSquare className={`${styles.inlineIcon}`} />
				Building Requirements
			</span>
		),
		content: (
			<React.Fragment>
				<p>
					First we&apos;ll sit down and map out the requirements of
					the team.
				</p>
				<p>
					The common data sources you use, what you want to be able to
					do and your challenges.
				</p>
			</React.Fragment>
		)
	},
	{
		title: (
			<span>
				<FaCheckSquare className={`${styles.inlineIcon}`} />
				Loading data & building pipelines
			</span>
		),
		content: (
			<React.Fragment>
				<p>
					We&apos;ll set you up a data warehouse and then go and load
					all your different data sources data into it.
				</p>
				<p>
					Once it&apos;s in, we&apos;ll build out pipelines for common
					SEO tasks.
				</p>
			</React.Fragment>
		)
	},
	{
		title: (
			<span>
				<FaCheckSquare className={`${styles.inlineIcon}`} />
				Setting up reports
			</span>
		),
		content: (
			<React.Fragment>
				<p>
					Once the pipelines are built out, we&apos;ll convert them
					into reports and set you up with our query platform for
					ad-hoc analysis.
				</p>
			</React.Fragment>
		)
	},
	{
		title: (
			<span>
				<FaCheckSquare className={`${styles.inlineIcon}`} />
				Updates and new reports
			</span>
		),
		content: (
			<React.Fragment>
				<p>Once you&apos;re set-up we don&apos;t just stop there.</p>
				<p>
					As we create new reports and workflows we can add them to
					your warehouse.
				</p>
				<p>
					And as a customer you also get a say in which reports we
					build next and roll out.
				</p>
			</React.Fragment>
		)
	},
	{
		title: (
			<span>
				<FaCheckSquare className={`${styles.inlineIcon}`} />
				Training and support
			</span>
		),
		content: (
			<React.Fragment>
				<p>We don&apos;t just set everything up and leave you to it.</p>
				<p>
					We&apos;ll work with your team to make sure they understand
					how to get the most out of their data.
				</p>
				<p>
					And we&apos;ll be here to support and answer the questions
					you&apos;ve got about it.
				</p>
			</React.Fragment>
		)
	}
];

const rankContent = [
	{
		title: (
			<span>
				<FaCheckSquare className={`${styles.inlineIcon}`} />
				Setting up the warehouse
			</span>
		),
		content: (
			<React.Fragment>
				<p>
					We&apos;ll get you set-up with a data warehouse so your rank
					data will be stored and yours for good.
				</p>
				<p>
					The heavy lifting is on us, but we&apos;ll also work with
					your tech team if necessary!
				</p>
			</React.Fragment>
		)
	},
	{
		title: (
			<span>
				<FaCheckSquare className={`${styles.inlineIcon}`} />
				Customisation
			</span>
		),
		content: (
			<React.Fragment>
				<p>
					You can just set everything up by yourself, but if you need
					help we&apos;ll walk you through the process.
				</p>
				<p>
					We&apos;ll talk though you choosing what to track for the
					different goals (performance tracking, brand management,
					featured snippet tracking etc.) and all the different config
					options.
				</p>
				<p>
					Because of the nature of our service, there are more
					configuration options than you&apos;ll find in many rank
					trackers. (Although we hope that&apos;ll also help you get
					more out of it.)
				</p>
			</React.Fragment>
		)
	},
	{
		title: (
			<span>
				<FaCheckSquare className={`${styles.inlineIcon}`} />
				Setting up reports & customisation
			</span>
		),
		content: (
			<React.Fragment>
				<p>
					Once we&apos;ve set-up the tracking, we&apos;ll set you up
					with the reports and perform any required pieces of
					customisation for you.
				</p>
				<p>
					We&apos;ll also walk you through the reports to make sure
					you understand the different metrics and what&apos;s
					possible!
				</p>
			</React.Fragment>
		)
	},
	{
		title: (
			<span>
				<FaCheckSquare className={`${styles.inlineIcon}`} />
				Updates and new reports
			</span>
		),
		content: (
			<React.Fragment>
				<p>Once you&apos;re set-up we don&apos;t just stop there.</p>
				<p>
					As we create new reports and workflows we can add them to
					your warehouse.
				</p>
				<p>
					And as a customer you also get a say in which reports we
					build next and roll out.
				</p>
			</React.Fragment>
		)
	}
];

const accordionContentSets = {
	default: accordionContent,
	rankService: rankContent
};

function HowItWorks({ title, contentSetKey }) {
	const accordionContentData = accordionContentSets[contentSetKey];

	const topPad = "p-t-xl";
	const botPad = "m-b-xl";

	return (
		<section className={`${styles.backgroundHelper} ${topPad} ${botPad}`}>
			<div
				className={`${styles.howitWorksContainer} container maxContainer outerContainer p-t-lg p-b-lg`}
			>
				<div className={`${styles.howitWorksContainerOuter}`}>
					<div className={`${styles.howItWorksPipesContainer}`}>
						<div className={`${styles.howItWorksPipesOuter}`}>
							<div
								className={`${styles.howItWorksPipes} columns is-desktop columnNoMargin`}
							>
								<div
									className={`column columnNoPad ${styles.howItWorksTitle}`}
								>
									<h2 className="is-size-2">{title}</h2>
								</div>
								<div
									className={`column columnNoPad ${styles.howItWorksPipesRightContent}`}
								>
									<Accordion data={accordionContentData} />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
}

HowItWorks.propTypes = propTypes;
HowItWorks.defaultProps = defaultProps;

export default HowItWorks;
